<template>
  <b-card title="">
    <div class="card-title">
      <div class="row">
        <div class="col-md-6">
          <h4 class="mb-0">Exports</h4>
        </div>
        <div class="col-md-6 text-right"></div>
      </div>
    </div>
    <div class="m-lg-5 mb-5">
      <table class="table table-hover">
        <tbody>
          <tr>
            <td><b>Utilisateurs sans commande</b></td>
            <td>
              Liste des utilisateurs ayant ouvert un compte sans commander.
            </td>
            <td class="text-right">
              <button
                class="btn btn-primary"
                @click="download('CUSTOMERS', 'guets.xlsx')"
              >
                Exporter
              </button>
            </td>
          </tr>
          <tr>
            <td><b>Liste des clients</b></td>
            <td>Liste des clients ayant ou non un abonnement actif.</td>
            <td class="text-right">
              <button
                class="btn btn-primary"
                @click="download('CUSTOMERS', 'customers.xlsx')"
              >
                Exporter
              </button>
            </td>
          </tr>
          <tr>
            <td><b>Sélections en cours</b></td>
            <td>Liste des clients ayant une sélection en cours.</td>
            <td class="text-right">
              <button
                class="btn btn-primary"
                @click="download('CUSTOMERS', 'subscriptions.xlsx')"
              >
                Exporter
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </b-card>
</template>

<script>
import { $themeConfig } from '@themeConfig'
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  data() {
    return {
      themeConfig: $themeConfig,
    }
  },
  computed: {},
  watch: {
    $route() {
      this.init()
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {},
    async download(type, name) {
      try {
        const respToken = await this.$http.post('/auth/token/', {
          payload: { type },
        })
        const { token } = respToken.data
        const { apiUrl } = this.themeConfig
        const params = new URLSearchParams({})
        console.log(this.themeConfig.apiUrl)
        window.location = `${apiUrl}/v1/private/exports/${token}/${name}?${params.toString()}`

        // console.log(response.data)
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
